export default {
  route: {
    dashboard: 'Intrahome',
    client: 'Επαφές',
    news: 'Ενημερώσεις',
    createNew: 'Νεά Ενημέρωση',
    newsList: 'Ενημερώσεις',
    createClient: 'Νέα Επαφή',
    editClient: 'Τροποποίηση Επαφής',
    clientList: 'Επαφές',
    call: 'Εισερχόμενα',
    createCall: 'Νέο Εισερ/νο',
    editCall: 'Τροποποίηση',
    callList: 'Κλήσεις',
    groupList: 'Ομάδες',
    office: 'Γραφεία',
    blog: 'Blog',
    createBlogPost: 'Νέα Ανάρτηση',
    editBlogPost: 'Τροποποίηση',
    blogPostList: 'Λίστα Αναρτήσεων',
    createOffice: 'Νέο Γραφείο',
    editOffice: 'Τροποποίηση Γραφείου',
    editOfficePage: 'Διαμόρφωση Σελίδας',
    officeList: 'Γραφεία',
    user: 'Συνεργάτες',
    createUser: 'Νέος Συνεργάτης',
    editUser: 'Τροποποίηση Συνεργάτη',
    userProfile: 'Προφίλ',
    userList: 'Συνεργάτες',
    property: 'Ακίνητα',
    visit: 'Υποδείξεις',
    createVisit: 'Νέα Υπόδειξη',
    visitList: 'Υποδείξεις',
    createProperty: 'Νέο Ακίνητο',
    editProperty: 'Τροποποίηση Ακινήτου',
    propertyList: 'Ακίνητα',
    propertyOfflineList: 'Offline Ακίνητα',
    auction: 'Πλειστηριασμοί',
    createAuction: 'Νέος πλειστηριασμός',
    editAuction: 'Τροποποίηση πλειστηριασμού',
    auctionList: 'Πλειστηριασμοί',
    auctionOfflineList: 'Offline Πλειστηριασμοί',
    role: 'Διαχείριση Ρόλων',
    roleList: 'Ρόλοι',
    incoming: 'Εισερχόμενες',
    outgoing: 'Εξερχόμενες',
    search: 'Ζητήσεις',
    searchList: 'Λίστα Ζητήσεων',
    createSearch: 'Νέα Ζήτηση',
    editSearch: 'Τροποποίηση Ζήτησης',
    editNew: 'Τροποποίηση Ενημέρωσης',
    privilege: 'Δικαιώματα',
    privilegeList: 'Δικαιώματα',
    recommendation: 'Συστάσεις',
    collaboratorsStatistics: 'Στατιστικά',
    createRecommendation: 'Νέα',
    editRecommendation: 'Τροποποίηση Σύστασης',
    recommendationList: 'Συστάσεις',
    collaboration: 'Συνεργασίες',
    createCollaboration: 'Νέα Συνεργασία',
    editCollaboration: 'Τροποποίηση Συνεργασίας',
    collaborationList: 'Συνεργασίες',
    internalMail: 'Intramail',
    webmail: 'Webmail - Εταιρικό email',
    internalMailList: 'Ιστορικό Aπεσταλμένων',
    internalMailCreate: 'Νέο',
    internalSmsCreate: 'Νέο',
    ticketing: 'Αιτ/τα τεχν/γίας',
    newTicket: 'Νέο',
    listTicket: 'Αιτήματα',
    editTicket: 'Τροποποίηση Αιτ/τος Τεχν/γίας',
    internalSmsList: 'SMS',
    supportList: 'Υποστήριξη',
    support: 'Υποστήριξη',
    create: 'Δημιουργία',
    edit: 'Τροποποίηση',
    supportMaterial: 'Υλικό Υποστήριξης',
    educationalMaterial: 'Εκπαι/τικό Υλικό',
    supportUsers: 'Συνεργάτες Bee',
    supportTalks: 'Ομιλίες',
    brokersTalks: 'Brokers',
    agentsTalks: 'Συνεργάτες',
    personalWebsiteManagement: 'Διαχείριση Προσωπικής Ιστοσελίδας',
    incomingPropertyRecommendationList: 'Ακινήτου',
    outgoingPropertyRecommendationList: 'Ακινήτου',
    incomingClientRecommendationList: 'Επαφής',
    outgoingClientRecommendationList: 'Επαφής',
    createPropertyRecommendation: 'Ακινήτου',
    createClientRecommendation: 'Επαφής',
    incomingCollaborationList: 'Εισερχόμενες',
    outgoingCollaborationList: 'Εξερχόμενες',
    changePassword: 'Αλλαγή Κωδικού'
  },
  dashboard: {
    title: 'Καλώς ήλθατε',
    collaborators: 'Ενεργοί Συνεργάτες',
    userStatistics: 'ΣΤΑΤΙΣΤΙΚΑ – ΣΥΝΕΡΓΑΤΗΣ',
    officeNewPropertiesTable: 'Νέα ακίνητα του γραφείου',
    spitogatosStatistics: 'Ακίνητα που είναι ενεργά στην Bee αλλά δεν είναι ενεργά στον Σπιτόγατο',
    officeNewApprovalsTable: 'Νέες εγκρίσεις του γραφείου',
    officeSentForApprovalTable: 'Ακίνητα προς έγκριση',
    properties: 'Ενεργά Ακίνητα',
    news: 'Ενημερώσεις',
    searches: 'Ενεργές Ζητήσεις',
    clients: 'Επαφές',
    home: 'Κεντρική Σελίδα',
    logOut: 'Αποσύνδεση',
    profile: 'Επεξεργασία Προφίλ',
    changePassword: 'Αλλαγή Κωδικού',
    propertiesForApproval: 'Ακίνητα προς Έγκριση',
    incomingCalls: 'Εισερχόμενες Κλήσεις',
    collaborations: 'Συνεργασίες',
    assignments: 'Αναθέσεις',
    foundFrom: 'Προέλευση Επαφών',
    visits: 'Υποδείξεις',
    searches_with_new_properties_count: 'Ζητήσεις με νέα ακίνητα',
    timeline: 'Χρονολόγιο',
    recommendations: 'Συστάσεις',
    same_office: 'Γραφείου',
    other_office: 'Εκτός γραφείου',
    sale_exclusive: 'Αποκλ. Πωλ.',
    sale_simple: 'Απλές Πωλ.',
    lease_exclusive: 'Αποκλ. Ενοικ.',
    lease_simple: 'Απλές Ενοικ.',
    date_from: 'Εμφάνιση Αποτελεσμάτων Από',
    date_to: 'Εμφάνιση Αποτελεσμάτων Εώς',
    viewsPerProperty: 'Επισκεψιμότητα Ακινήτων',
    today: 'Σήμερα',
    yesterday: 'Χθες',
    last_seven_days: 'Τελευταίες 7 ημέρες',
    current_week: 'Τρέχουσα εβδομάδα',
    last_thirty_days: 'Τελευταίες 30 ημέρες',
    current_month: 'Τρέχον Μήνας',
    last_six_months: 'Τελευταίοι 6 μήνες',
    last_year: 'Τελευταίος Χρόνος',
    current_year: 'Τρέχον Έτος',
    choose_dates: 'Ημερομηνίες',
    custom: 'Επίλεξε ημερομηνίες'
  },
  formValidationErrors: {
    invalidLatitude: 'Μη έγκυρο Γεωγραφικό Πλάτος',
    invalidLongitude: 'Μη έγκυρο Γεωγραφικό Μήκος',
    yearValidation: 'Πρέπει να είναι μεταξύ 1901 και {currentYear}'
  },
  notificationList: {
    title: 'Ειδοποιήσεις'
  },
  auctionList: {
    title: 'Πλειστηριασμοί'
  },
  clientList: {
    title: 'Επαφές',
    delete: 'Η επιλεγμένη επαφή πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteBulk: 'Οι επιλεγμένες επαφές πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Η επαφή διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Οι επιλεγμένες επαφές διαγράφηκαν επιτυχώς!',
    selectClients: 'Επιλέξτε τις επαφές που θέλετε να διαγράψετε',
    warning: 'Προσοχή',
    download: 'Εξαγωγή/Λήψη Λίστας',
    create: 'Δημιουργία Επαφής',
    deleteBulkClients: 'Μαζική Διαγραφή Επαφών',
    filters: 'Φίλτρα'
  },
  supportMaterial: {
    title: 'Υλικό υποστήριξης',
    commands: 'Εντολές',
    promotionalMaterial: 'Υλικό προώθησης',
    logos: 'Λογότυπα',
    logosForEmpty: 'Εικονίδια για ακίνητα χωρίς φωτογραφίες',
    registrationForms: {
      title: 'Έντυπα καταγραφής',
      residence: 'Έντυπο καταγραφής κατοικίας',
      professionalSpace: 'Έντυπο καταγραφής επαγγελματικού χώρου',
      land: 'Έντυπο καταγραφής γης'
    },
    evaluationForms: {
      title: 'Έντυπα αξιολόγησης',
      buyer: 'Έντυπο αξιολόγησης ενδιαφερομένου αγοραστή',
      seller: 'Έντυπο αξιολόγησης πωλητή',
      collaborator: 'Έντυπο αξιολόγησης συνεργάτη'
    },
    searchForms: {
      title: 'Έντυπα ζήτησης',
      property: 'Έντυπα ζήτησης ακινήτου'
    },
    propertyFolder: {
      title: 'Φάκελος ακινήτου',
      propertyCard: 'Καρτέλα ακινήτου',
      folder1: 'Φάκελος ακινήτου-1',
      folder3: 'Φάκελος ακινήτου-3'
    },
    cooperationWithOtherRealEstateAgencies: {
      title: 'Έντυπα συνεργασίας με άλλα μεσιτικά γραφεία',
      cooperation: 'Έντυπο συνεργασίας',
      recommendation: 'Έντυπο σύστασης'
    },
    offerForms: {
      title: 'Έντυπα προσφοράς',
      sale: 'Έντυπο προσφοράς αγοράς',
      rent: 'Έντυπο προσφοράς μίσθωσης'
    },
    leaseStandards: {
      title: 'Μισθωτήρια (πρότυπα)',
      mainResidence: 'Μισθωτήριο κύριας κατοικίας',
      professionalHousing: 'Μισθωτήριο επαγγελματικής στέγης'
    },
    marketingPlan: {
      title: 'Marketing Plan',
      presentation: 'Παρουσίαση',
      fonts: 'Απαραίτητες Γραμματοσειρές'
    },
    generalForms: {
      title: 'Έντυπα γενικά',
      prelistPack: 'PRELIST PACK - Πλάνο προώθησης της BEE REAL ESTATE',
      thingsLoveHateAboutHome: '10 πράγματα που λατρεύω/θα άλλαζα στο σπίτι μου',
      openHouse: 'Οργανόγραμμα Open House',
      sellerDocuments: 'Απαραίτητα δικαιολογητικά πωλητή ακινήτου',
      englishVocabulary: 'Λεξιλόγιο στα Αγγλικά',
      advancePaymentReceipt: 'Απόδειξη λήψης προκαταβολής',
      callCard: 'Καρτέλα κλήσεων'
    },
    propertySale: {
      title: 'Πώληση κινήτου',
      simpleAssignment1000: 'Εντολή απλής ανάθεσης για πώληση ακινήτου (ελάχιστη αμοιβή 1.000€+φπα)',
      simpleAssignment1500: 'Εντολή απλής ανάθεσης για πώληση ακινήτου (ελάχιστη αμοιβή 1.500€+φπα)',
      exclusiveAssignment1000: 'Εντολή αποκλειστικής ανάθεσης για πώληση ακινήτου (ελάχιστη αμοιβή 1.000€+φπα)',
      exclusiveAssignment1500: 'Εντολή αποκλειστικής ανάθεσης για πώληση ακινήτου (ελάχιστη αμοιβή 1.500€+φπα)',
      exclusiveAssignment: 'Αποκλειστική εντολή ανάθεσης (Ελληνικά-Αγγλικά)'
    },
    propertyRent: {
      title: 'Εκμίσθωση ακινήτου',
      simpleAssignment: 'Εντολή απλής ανάθεσης για εκμίσθωση ακινήτου',
      exclusiveAssignment: 'Εντολή αποκλειστικής ανάθεσης για εκμίσθωση ακινήτου',
      simpleAssignmentSale: 'Απλή εντολή ανάθεσης πώλησης (Ελληνικά-Αγγλικά)'
    },
    propertyVisit: {
      title: 'Υπόδειξη ακινήτου',
      visitOrder1000: 'Εντολή υπόδειξης (ελάχιστη αμοιβή 1.000€+φπα)',
      visitOrder1500: 'Εντολή υπόδειξης (ελάχιστη αμοιβή 1.500€+φπα)',
      visitOrderInt: 'Εντολή υπόδειξης (Ελληνικά-Αγγλικά)'
    },
    otherLanguages: {
      title: 'Λοιπές γλώσσες (απαιτείται επεξεργασία από το κάθε γραφείο)',
      albanian: 'Εντολή υπόδειξης (Ελληνικά/Αλβανικά)',
      french: 'Εντολή υπόδειξης (Ελληνικά/Γαλλικά)',
      german: 'Εντολή υπόδειξης (Ελληνικά/Γερμανικά)',
      spanish: 'Εντολή υπόδειξης (Ελληνικά/Ισπανικά)',
      italian: 'Εντολή υπόδειξης (Ελληνικά/Ιταλικά)',
      chinese: 'Εντολή υπόδειξης (Ελληνικά/Κινέζικα)',
      turkish: 'Εντολή υπόδειξης (Ελληνικά/Τούρκικα)',
      bulgarian: 'Εντολή υπόδειξης (Ελληνικά/Βουλγάρικα)',
      russian: 'Εντολή υπόδειξης (Ελληνικά/Ρώσικα)',
      serbian: 'Εντολή υπόδειξης (Ελληνικά/Σέρβικα)'
    },
    manuals: {
      title: 'Εγχειρίδια - Οδηγοί χρήσης',
      broker: 'Intrahome Manual για Brokers',
      secretariat: 'Intrahome Manual για Γραμματείες',
      collaborator: 'Intrahome Manual για Συνεργάτες',
      email: 'Οδηγός χρήσης Intra-Mail'
    },
    services: {
      spitogatos: 'Οδηγός ορισμού προβεβλημένων αγγελιών - spitogatos.gr',
      xe: 'Οδηγός ορισμού προβεβλημένων αγγελιών - xe.gr',
      youtube: 'Οδηγός δημιουργίας βίντεο προβολής ακινήτου - YouTube',
      spaces3d: 'Τιμοκατάλογος 3D Spaces (Σύγχρονα Μέσα Προβολής) Μελών Bee real estate'
    }
  },
  callList: {
    title: 'Λίστα Εισερχομένων'
  },
  ticketList: {
    title: 'Λίστα Αιτημάτων'
  },
  internalMailList: {
    title: 'Ιστορικό Aπεσταλμένων'
  },
  callDetail: {
    new: 'Νέο Αίτημα',
    client: 'Υπάρχουσα Επαφή',
    info: 'Σημείωση',
    generalInstructions: 'Σε περίπτωση που επιλέξετε υπάρχουσα επαφή, δεν δίνεται η δυνατότητα προσθήκης νέας επαφής',
    instructions: 'Εάν πρόκειται για νέα επαφή συμπληρώνετε παρακάτω',
    edit: 'Τροποποίηση Εισερχομένου',
    clientInfo: 'Λεπτομέρειες Νέας Επαφής',
    callInfo: 'Λεπτομέρειες Εισερχομένου',
    createClient: 'Δημιουργία Επαφής',
    notes: 'Σημειώσεις Εισερχομένου'
  },
  ticketDetail: {
    new: 'Νέο Αίτ/μα Τεχν/γίας',
    edit: 'Τροποποίηση Αιτ/τος Τεχν/γίας'
  },
  pages: {
    home: 'ΑΡΧΙΚΗ',
    jobs: 'ΝΕΕΣ ΘΕΣΕΙΣ ΕΡΓΑΣΙΑΣ',
    info: 'ΠΛΗΡΟΦΟΡΙΕΣ',
    blog: 'BLOG',
    favorites: 'ΑΓΑΠΗΜΕΝΑ',
    franchise: 'FRANCHISE',
    team: 'ΟΜΑΔΑ',
    our_offices: 'ΤΑ ΓΡΑΦΕΙΑ ΜΑΣ',
    search: 'ΑΝΑΖΗΤΗΣΗ'
  },
  personalWebsiteManagement: {
    colors: 'Χρώματα',
    menu: 'Μενού',
    footer: 'Footer',
    js: 'Js',
    css: 'Css',
    footerHtml: 'Footer HTML',
    job_openingsHtml: 'HTML',
    infoHtml: 'HTML',
    section1: '1ο τμήμα',
    section2: '2ο τμήμα',
    section3: '3ο τμήμα',
    section4: '4ο τμήμα',
    section5: '5ο τμήμα',
    section6: '6ο τμήμα',
    general: 'Γενικά',
    primaryColor: 'Κεντρικό Χρώμα',
    backgroundColor: 'Χρώμα Παρασκηνίου',
    mainPage: 'Κεντρική Σελίδα',
    defaultTitle: 'Διαχείριση Ιστοσελίδας',
    title: 'Διαχείριση Προσωπικής Ιστοσελίδας',
    social_media: 'Κοινωνικά δίκτυα',
    page_titles: 'Τίτλοι σελίδων',
    job_openings: 'Νέες Θέσεις εργασίας',
    blog: 'Blog',
    info: 'Πληροφορίες',
    my_properties: 'Τα ακίνητά μου',
    logo: 'Λογότυπο'
  },
  collaborationList: {
    incomingList: 'Εισερχόμενες Συνεργασίες',
    outgoingList: 'Εξερχόμενες Συνεργασίες',
    accept: 'Είστε σίγουρος ότι θέλετε να αποδεχθείτε τη συνεργασία;'
  },
  internalSmsCreate: {
    title: 'Αποστολή SMS',
    instructions: 'Βήματα για την υπηρεσία SMS:<br>1) Δημιουργούμε λογαριασμό και επιλέγετε το πακέτο SMS στην υπηρεσία <a href=\'https://www.sms.net.gr\' target=\'_blank\'>http://www.sms.net.gr/</a><br>2) Ζητάτε από την υπηρεσία να σας στείλουν τα Username, Api Password, Api Token για χρήση του API<br>3) Μόλις σας τα στείλουν με mail τα βάζετε στα σχετικά παιδία στο profile σας και είστε έτοιμοι!',
    content: 'Περιοχή Μηνύματος',
    actualMessage: 'Πραγματικό Μήνυμα',
    charsLeft: 'Υπολειπόμενοι χαρακτήρες',
    smsToSent: 'Συνολικά sms προς αποστολή',
    credits: 'Χρέωση credit',
    specificPhone: 'Συγκεκριμένο τηλέφωνο'
  },
  internalMailCreate: {
    title: 'Αποστολή Email',
    instructions: 'Μπορείτε να στείλετε mail επιλέγοντας είτε από μέλη της Bee είτε από τις επαφές σας. Επίσης μπορείτε να επιλέξετε ακίνητα ή/και εικόνα ή/και κείμενο που θα περιλαμβάνει το email σας. Περιορισμοί: δεν μπορείτε να στείλετε email στον εαυτό σας.',
    sendTo: 'Αποστολή σε',
    chooseFrom: 'Επιλογή Από',
    clients: 'Επαφές',
    clients_groups: 'Ομάδες Επαφών',
    users: 'Συνεργάτες',
    users_groups: 'Ομάδες Συνεργατών',
    office: 'Όλο το γραφείο',
    secretariat: 'Στη γραμματεία του γραφείου',
    broker: 'Στον broker του γραφείου',
    company: 'Σε όλη την Bee Real Estate',
    offices_thessaloniki: 'Σε όλα τα γραφεία Θεσσσαλονίκης',
    offices_athens: 'Σε όλα τα γραφεία Αθήνας',
    all_broker: 'Σε όλους τους Brokers',
    all_secretariat: 'Σε όλες τις Γραμματείες',
    all_collaborator: 'Σε όλους τους Συνεργάτες',
    with_header: 'Με επικεφαλίδα (header)',
    support: 'Στο Support'
  },
  recommendationDetail: {
    new: 'Εισαγωγή Σύστασης',
    edit: 'Τροποποίηση Σύστασης',
    incomingList: 'Εισερχόμενες Συνεργασίες',
    outgoingList: 'Εξερχόμενες Συνεργασίες',
    client: 'Επαφή Σύστασης',
    property: 'Ακίνητο Σύστασης',
    details: 'Λεπτομέρειες Σύστασης',
    accept: 'Είστε σίγουρος ότι θέλετε να αποδεχθείτε τη σύσταση;'
  },
  recommendationList: {
    title: 'Συστάσεις',
    incomingProperty: 'Εισερχόμενες Ακινήτου',
    outgoingProperty: 'Εξερχόμενες Ακινήτου',
    incomingClient: 'Εισερχόμενες Επαφής',
    outgoingClient: 'Εξερχόμενες Επαφής',
    rejectionDialogTitle: 'Απόρριψη Σύστασης'
  },
  visitList: {
    title: 'Υποδείξεις',
    delete: 'Η επιλεγμένη υπόδειξη πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteBulk: 'Οι επιλεγμένες υποδείξεις πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Η επιλεγμένη υπόδειξη διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Οι επιλεγμένες υποδείξεις διαγράφηκαν επιτυχώς!',
    selectVisits: 'Επιλέξτε τις υποδείξεις που θέλετε να διαγράψετε',
    warning: 'Προσοχή',
    download: 'Εξαγωγή/Λήψη Λίστας',
    create: 'Δημιουργία Επαφής',
    deleteBulkClients: 'Μαζική Διαγραφή Επαφών',
    filters: 'Φίλτρα'
  },
  searchList: {
    title: 'Ζητήσεις',
    download: 'Εξαγωγή/Λήψη Λίστας',
    create: 'Δημιουργία Ζήτησης',
    deleteBulkClients: 'Μαζική Διαγραφή Ζητήσεων',
    filters: 'Φίλτρα'
  },
  roleList: {
    news: 'Ενημερώσεις',
    auctions: 'Πλειστηριασμοί',
    title: 'Ρόλοι',
    saveRole: 'Αποθήκευση Ρόλου',
    addRole: 'Νέος Ρόλος',
    deleteRole: 'Διαγραφή Ρόλου',
    create: 'Δημιουργία',
    view: 'Πρόσβαση',
    read: 'Ανά/ση',
    read_others: 'Ανά/ση Άλλων',
    edit: 'Τροπ/ση',
    edit_others: 'Τροπ/ση Άλλων',
    delete: 'Δια/φή',
    delete_others: 'Δια/φή Άλλων',
    list: 'Λίστα',
    approve: 'Έγκριση',
    list_others: 'Λίστα Άλλων',
    properties: 'Ακίνητα',
    clients: 'Επαφές',
    groups: 'Ομάδες',
    bridge_events: 'Γεγονότα Γέφυρας',
    theme_templates: 'Πρότυπα Θεμάτων',
    blog_posts: 'Αναρτήσεις Blog',
    copyFrom: 'Αντιγραφή Δικαιωμάτων από',
    nameRequired: 'Το όνομα είναι υποχρεωτικό',
    deleteSuccess: 'Ο ρόλος διαγράφηκε επιτυχώς!',
    saveSuccess: 'Ο ρόλος αποθηκεύτηκε επιτυχώς!',
    roleRequired: 'Η επιλογή ρόλου είναι υποχρεωτική',
    users: 'Χρήστες',
    roles: 'Διαχείριση Ρόλων',
    selectAll: 'Προσθήκη Όλων',
    deselectAll: 'Διαγραφή Όλων',
    offices: 'Γραφεία',
    tasks: 'Καθήκοντα',
    visits: 'Υποδείξεις',
    searches: 'Αναζητήσεις',
    notifications: 'Ειδοποιήσεις',
    privileges: 'Δικαιώματα',
    recommendations: 'Συστάσεις',
    emails: 'Emails',
    collaboratorstatistics: 'Στατιστικά Συνεργατών',
    calls: 'Κλήσεις',
    collaborations: 'Συνεργασίες',
    tickets: 'Αιτήματα Τεχνολογίας',
    other_offices_users: 'Πρόσβαση σε χρήστες άλλου γραφείου'
  },
  roles: {
    none: 'Κανένας',
    collaborator: 'Συνεργάτης',
    broker: 'Broker',
    secretariat: 'Γραμματεία',
    central_secretariat: 'Κεντρική Γραμματεία',
    theme_templates: 'Διαχειριστής UI',
    accounts_development: 'Λογαριασμός Ανάπτυξης',
    paid_package_role: 'Έξτρα πακέτο διαχείρισης',
    super_admin: 'Διαχ/στής Συστή/ατος'
  },
  propertyList: {
    title: 'Ακίνητα',
    offlineTitle: 'Offline Ακίνητα',
    delete: 'Το επιλεγμένο ακίνητο πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteBulk: 'Τα επιλεγμένα ακίνητα πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Το επιλεγμένο ακίνητο διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Τα επιλεγμένα ακίνητα διαγράφηκαν επιτυχώς!',
    warning: 'Προσοχή',
    selectProperties: 'Επιλέξτε τα ακίνητα που θέλετε να διαγράψετε',
    copyPropertyContent: 'Πρόκειται να δημιουργήσετε ένα ακριβές αντίγραφο. Θέλετε να προχωρήσετε?',
    download: 'Εξαγωγή/Λήψη Λίστας Ακινήτων',
    create: 'Δημιουργία Ακινήτου',
    deleteBulkProperties: 'Μαζική Διαγραφή Ακινήτων',
    filters: 'Φίλτρα',
    show_to: 'Εμφάνιση σε',
    previous_codes: 'Προηγούμενοι:',
    changeOwner: 'Μεταφορά σε συνεργάτη',
    no_services: 'Καμία υπηρεσία'
  },
  visitDetail: {
    visit: 'Υπόδειξη',
    newVisit: 'Εισαγωγή Υποδείξεων',
    editVisit: 'Τροποποίηση Υπόδειξης',
    formErrors: 'Η φόρμα της υπόδειξης περιέχει σφάλματα!',
    successSave: 'Επιτυχής αποθήκευση υπόδειξης'
  },
  collaborationDetail: {
    new: 'Εισαγωγή Συνεργασίας',
    edit: 'Τροποποίηση Συνεργασίας',
    waiting: 'Αναμονή',
    approved: 'Αποδεκτή',
    rejected: 'Μη Αποδεκτή',
    canceled: 'Ακυρώθηκε',
    apiCancel: 'Η επιλεγμενη συνεργασία πρόκειται να ακυρωθεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    apiCancelSuccess: 'Η επιλεγμενη καταχώρηση ακυρώθηκε επιτυχώς!'
  },
  searchDetail: {
    search: 'Ζήτηση',
    newSearch: 'Εισαγωγή Ζήτησης',
    editSearch: 'Τροποποίηση Ζήτησης',
    formErrors: 'Η φόρμα της Ζήτησης περιέχει σφάλματα!',
    successSave: 'Επιτυχής αποθήκευση Ζήτησης',
    results: 'Αποτελέσματα',
    activeSearch: 'Ενεργή Ζήτηση',
    date_updated: 'Τελευταία Ενημέρωση',
    price_asc: 'Αύξουσα Τιμή',
    price_desc: 'Φθίνουσα Τιμή',
    acreage_asc: 'Φθίνον Εμβαδόν',
    acreage_desc: 'Αύξων Εμβαδόν',
    isNewProperty: 'Νέο Ακίνητο',
    drawMap: 'Σχεδιασμός Χάρτη',
    startDrawMap: 'Έναρξη Σχεδιασμού Χάρτη',
    clearDrawMap: 'Ακύρωση Σχεδιασμού',
    newClient: 'Εισαγωγή Νέας Επαφής',
    filters: 'Χαρακτηριστικά'
  },
  clientDetail: {
    newClient: 'Εισαγωγή Επαφής',
    editClient: 'Τροποποίηση Επαφής',
    firstName: 'Όνομα',
    form: 'Φόρμα Συμπλήρωσης',
    additionalInfo: 'Επιπλέον Πληροφορίες',
    lastName: 'Επίθετο',
    identityCard: ' Αριθμός ΔΤ',
    foundFrom: 'Μας βρήκε από',
    profession: 'Ειδικότητα',
    mainPhone: 'Τηλέφωνο (σταθερό)',
    secondaryPhone: 'Τηλέφωνο (κινητό)',
    address: 'Οδός',
    addressNumber: 'Αριθμός',
    postCode: 'Τ.Κ.',
    age: 'Ηλικία',
    mainEmail: 'Email',
    fax: 'Φαξ',
    taxIdentificationNumber: 'ΑΦΜ',
    groups: 'Ομάδες',
    notes: 'Σημειώσεις',
    formErrors: 'Η φόρμα της επαφής περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSaveClient: 'Επιτυχής αποθήκευση επαφής'
  },
  officeList: {
    title: 'Γραφεία',
    delete: 'Το επιλεγμένο γραφείο πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteBulk: 'Τα επιλεγμένα γραφεία πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Το γραφείο διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Τα επιλεγμένα γραφεία διαγράφηκαν επιτυχώς!',
    warning: 'Προσοχή',
    download: 'Εξαγωγή/Λήψη Λίστας',
    create: 'Δημιουργία Γραφείου',
    deleteBulkOffices: 'Μαζική Διαγραφή Γραφείων',
    selectOffices: 'Επιλέξτε τα γραφεία που θέλετε να διαγράψετε'
  },
  blogPostList: {
    title: 'Blog',
    delete: 'Η επιλεγμένη ανάρτηση πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteBulk: 'Οι επιλεγμένες αναρτήσεις πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Η ανάρτηση διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Οι επιλεγμένες αναρτήσεις διαγράφηκαν επιτυχώς!',
    warning: 'Προσοχή',
    download: 'Εξαγωγή/Λήψη Λίστας',
    create: 'Δημιουργία Ανάρτησης',
    deleteBulkOffices: 'Μαζική Διαγραφή Αναρτήσεων',
    selectOffices: 'Επιλέξτε τις αναρτήσεις που θέλετε να διαγράψετε'
  },
  userList: {
    title: 'Συνεργάτες',
    delete: 'Ο επιλεγμένος συνεργάτης πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteBulk: 'Οι επιλεγμένοι χρήστες πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Ο χρήστης διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Οι επιλεγμένοι χρήστες διαγράφηκαν επιτυχώς!',
    warning: 'Προσοχή',
    selectUsers: 'Επιλέξτε τους χρήστες που θέλετε να διαγράψετε',
    download: 'Εξαγωγή/Λήψη Λίστας',
    create: 'Δημιουργία Χρήστη',
    deleteBulkUsers: 'Μαζική Διαγραφή Χρηστών',
    filters: 'Φίλτρα'
  },
  officeDetail: {
    newOffice: 'Εισαγωγή Γραφείου',
    editOffice: 'Τροποποίηση Γραφείου',
    formErrors: 'Η φόρμα του γραφείου περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSave: 'Επιτυχής αποθήκευση γραφείου',
    spitogatosUsername: 'Σπιτόγατος-Όνομα Χρήστη',
    spitogatosPassword: 'Σπιτόγατος-Κωδικός Πρόσβασης',
    xeUsername: 'Χρυσή Ευκαιρία-Όνομα Χρήστη',
    xePassword: 'Χρυσή Ευκαιρία-Κωδικός Πρόσβασης',
    xe_agent_id: 'Agent ID',
    xe_auth: 'Auth',
    basicAttributes: 'Βασικά Στοιχεία',
    location: 'Τοποθεσία Γραφείου',
    contactDetails: 'Στοιχεία Επικοινωνίας',
    portalPhone: 'Τηλέφωνο σε Portal',
    companyName: 'Επωνυμία Εταιρείας',
    certificateNumber: 'Αριθ. Πιστοπ.',
    realtorsAssociation: 'Σύλλογος Μεσιτών',
    taxIdentificationNumber: 'ΑΦΜ',
    publicRevenueOffice: 'Δ.Ο.Υ.',
    competentCourt: 'Αρμόδιο Πρωτοδικείο'
  },
  blogPostDetail: {
    newBlogPost: 'Εισαγωγή Ανάρτησης',
    pickDate: 'Επιλέξτε Ημερομηνία',
    editBlogPost: 'Τροποποίηση Ανάρτησης',
    formErrors: 'Η φόρμα του γραφείου περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSave: 'Επιτυχής αποθήκευση ανάρτησης'
  },
  userDetail: {
    newUser: 'Εισαγωγή Συνεργάτη',
    changePassword: 'Αλλαγή Κωδικού',
    editUser: 'Τροποποίηση Συνεργάτη',
    formErrors: 'Η φόρμα του χρήστη περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    profile: 'Εισαγωγή στοιχείων',
    successSave: 'Επιτυχής αποθήκευση χρήστη',
    smsApi: 'SMS Api',
    socialMedia: 'Κοινωνικά Δίκτυα',
    insuranceData: 'Ασφαλιστικά Στοιχεία',
    systemData: 'Πληροφορίες Συστήματος',
    contactDetails: 'Στοιχεία Επικοινωνίας',
    address: 'Στοιχεία Διεύθυνσης',
    basicAttributes: 'Βασικά Στοιχεία',
    additionalAttributes: 'Συμπληρωματικά Στοιχεία'
  },
  groupDetail: {
    newGroup: 'Εισαγωγή Ομάδας',
    editGroup: 'Τροποποίηση Ομάδας',
    formErrors: 'Η φόρμα της ομάδας περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSave: 'Επιτυχής αποθήκευση ομάδας'
  },
  privilegeDetail: {
    new: 'Εισαγωγή Δικαιώματος',
    edit: 'Τροποποίηση Δικαιώματος',
    formErrors: 'Η φόρμα του δικαιώματος περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSave: 'Επιτυχής αποθήκευση δικαιώματος'
  },
  generatePrivilegesDetail: {
    title: 'Δημιουργία Δικαιωμάτων',
    list: 'Λίστα Δικαιωμάτων (Χωρίζονται με κόμμα)',
    formErrors: 'Η φόρμα του δικαιώματος περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSave: 'Επιτυχής αποθήκευση δικαιωμάτων'
  },
  propertyValidation: {
    available_for: 'Το πεδίο "Διαθέσιμο προς" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    levels: 'Το πεδίο "Επίπεδα" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    category: 'Το πεδίο "Κατηγορία Ακινήτου" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    type: 'Το πεδίο "Είδος Ακινήτου" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    floor: 'Το πεδίο "Όροφος" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    state: 'Το πεδίο "Κατάσταση Ακινήτου" που βρισκεται στην καρτέλα "Κατασκευή", είναι υποχρεωτικό',
    construction_year: 'Το πεδίο "Έτος κατασκευής" που βρισκεται στην καρτέλα "Κατασκευή", είναι υποχρεωτικό',
    energy_class: 'Το πεδίο "Ενεργειακή Κλάση" που βρισκεται στην καρτέλα "Θέρμανση & κατανάλωση", είναι υποχρεωτικό',
    code: 'Το πεδίο "Κωδικός" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    price: 'Το πεδίο "Τιμή" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    acreage: 'Το πεδίο "Εμβαδόν" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    client_id: 'Το πεδίο "Ιδιοκτήτης" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    area_id: 'Το πεδίο "Περιοχή" που βρισκεται στην καρτέλα "Τοποθεσία", είναι υποχρεωτικό',
    assignation_state: 'Το πεδίο "Κατάσταση Ανάθεσης" που βρισκεται στην καρτέλα "Βασικά Στοιχεία", είναι υποχρεωτικό',
    field_changes: 'Το πεδίο έχει τροποποιηθεί από τον συνεργάτη',
    images: 'Οι φωτογραφίες έχουν τροποποιηθεί από τον συνεργάτη',
    images360: 'Οι φωτογραφίες 360 έχουν τροποποιηθεί από τον συνεργάτη',
    videos: 'Το Youtube video έχει τροποποιηθεί από τον συνεργάτη',
    e_auction_link: 'Το πεδίο "Σύνδεσμος E-Auction" που βρισκεται στην καρτέλα "Επιπλέον Στοιχεία", είναι υποχρεωτικό',
    auction_date: 'Το πεδίο "Ημερομηνία διενέργειας πλειστηριασμού" που βρισκεται στην καρτέλα "Επιπλέον Στοιχεία", είναι υποχρεωτικό',
    latitude: 'Το πεδίο "Γεωγραφικό Πλάτος" που βρισκεται στην καρτέλα "Τοποθεσία", έχει μη έγκυρη τιμή',
    longitude: 'Το πεδίο "Γεωγραφικό Μήκος" που βρισκεται στην καρτέλα "Τοποθεσία", έχει μη έγκυρη τιμή',
    yearValidation: 'Το πεδίο "{fieldName}" που βρισκεται στην καρτέλα "Κατασκευή", πρέπει να είναι μεταξύ 1901 και {currentYear}'
  },
  userValidation: {
    pleaseInputPassword: 'Εισαγάγετε τον κωδικό πρόσβασης',
    pleaseInputPasswordAgain: 'Εισαγάγετε ξανά τον κωδικό πρόσβασης',
    twoInputsDontMatch: 'Οι δύο κωδικοί δεν ταιριάζουν',
    atLeastCharacters: 'Το μέγεθος πρέπει να είναι τουλάχιστον 5 χαρακτήρες'
  },
  propertyDetail: {
    e_auction_link: 'Σύνδεσμος E-Auction',
    pickYear: 'Επιλέξτε Χρονιά',
    pickDate: 'Επιλέξτε Ημερομηνία',
    calls: 'Εισερχόμενα Τηλέφωνα',
    newProperty: 'Εισαγωγή Ακινήτου',
    translations: 'Μεταφράσεις',
    dateUpdated: 'Τελευταία Ενημ/ση',
    dateCreated: 'Ημ/νία Εισ/γής',
    walkthrouUrl: '3D Walk through url',
    updatedBy: 'Ενημερώθηκε από διαφορετικό χρήστη',
    another_client: 'Άλλη επαφή',
    video_instructions: 'Επικόλλησε τη YouTube διεύθυνση από το βίντεο του ακινήτου.',
    revisions: 'Ιστορικό Αλλαγών',
    propertyHistory: 'Ιστορικό Ακινήτου',
    assignationStateError: 'Το ακίνητο πρέπει να είναι είτε {active}, είτε {under_negotiation}, είτε {under_offer_status}',
    addressNumber: 'Αριθμός',
    postCode: 'Τ.Κ.',
    editFormErrors: 'Τα πεδία {fields} είναι υποχρεωτικά',
    video: 'Βίντεο',
    view_less: 'Λιγότερα Φίλτρα',
    view_more: 'Περισσότερα Φίλτρα',
    confirm: 'ΕΜΦΑΝΙΣΗ ΑΠΟΤΕΛΕΣΜΑΤΩΝ',
    min: 'Ελάχιστο',
    mediaSelection: 'Σύρετε το αρχείο εδώ ή κάντε κλικ για μεταφόρτωση',
    services_history: 'Ιστορικό Υπηρεσιών',
    property_traffic: 'Επισκέψεις Ακινήτου',
    max: 'Μέγιστο',
    view: 'Θέα',
    selected: 'Επιλεγμένο',
    live: 'Live',
    notSelected: 'Μη Επιλεγμένο',
    services: 'Υπηρεσίες',
    under_construction: 'Υπό Κατασκευή',
    under_construction_short: 'Υπό Κατ.',
    property_type: 'Είδος Ακινήτου',
    type: 'Είδος Ακινήτου',
    price_range: 'Τιμή',
    successful_entry: 'Επιτυχής Ενημέρωση',
    service_delete: 'Επιτυχής διαγραφή αγγελίας',
    service_save: 'Επιτυχές ανέβασμα αγγελίας',
    price_range_selection: 'Επιλογή Τιμής',
    sea_distance_title: 'Απόσταση από τη Θάλασσα',
    sea_distance_selection: 'Επιλογή Απόστασης',
    framing_title: 'Κουφώματα',
    framing_selection: 'Επιλογή Κουφωμάτων',
    confirmation_date_title: 'Τελευταία Ενημέρωση',
    property_type_title: 'Υποκατηγορία',
    property_type_selection: 'Είδος Ακινήτου',
    energy_class_title: 'Ενεργειακή Κλάση',
    energy_class_selection: 'Επιλογή Κλάσης',
    heating_title: 'Θέρμανση',
    heating_selection: 'Επιλογή Θέρμανσης',
    construction_year_title: 'Έτος Κατασκευής',
    construction_year_selection: 'Επιλογή Έτους Κατασκευής',
    floor_title: 'Όροφος',
    floor_selection: 'Επιλογή ορόφου',
    rooms_title: 'Υπνοδωμάτια',
    rooms_selection: 'Επιλογή Υπνοδωμάτια',
    bathrooms_title: 'Μπάνια',
    bathrooms_selection: 'Ελάχιστα Μπάνια',
    acreage_title: 'Εμβαδόν σε τ.μ.',
    acreage_selection: 'Εμβαδόν σε τ.μ.',
    characteristics: 'Χαρακτηριστικά',
    sale: 'Πώληση',
    lease: 'Ενοικίαση',
    penthouse: 'Ρετιρέ',
    furnished: 'Επιπλωμένο',
    warehouse: 'Αποθήκη',
    safety_door: 'Πόρτα Ασφαλείας',
    safetyDoor: 'Πόρτα Ασφαλείας',
    alarm: 'Συναγερμός',
    elevator: 'Ασανσέρ',
    garden: 'Κήπος',
    solar_water_heater: 'Ηλιακός Θερμοσίφωνας',
    parking: 'Χώρος Στάθμευσης',
    veranda: 'Βεράντες',
    air_conditioning: 'Κλιματισμός',
    fireplace: 'Τζάκι',
    openness_view: 'Θέα Ανοιχτωσιά',
    pool: 'Πισίνα',
    power_marketing: 'Power Marketing',
    airy: 'Διαμπερές',
    cottage: 'Εξοχικό',
    student: 'Φοιτητικό',
    investment: 'Επενδυτικό',
    floor_apartment: 'Οροφοδιαμέρισμα',
    mild_sanitation: 'Ήπιου Υγειονομικού',
    funnel: 'Υγ. Ενδιαφ. (Φουγάρο)',
    facade: 'Πρόσοψης',
    suspended_ceiling: 'Ψευδοροφή',
    staircase: 'Εσωτερική Σκάλα',
    floor_heating: 'Ενδοδαπέδια Θέρμανση',
    neoclassic: 'Νεοκλασικό',
    preserved: 'Διατηρητέο',
    pets_allowed: 'Επιτρέπονται Κατοικίδια',
    night_electricity: 'Νυχτερινό Ρεύμα',
    with_equipment: 'Με Εξοπλισμό',
    cargo_lift: 'Ανελκυστήρας Φορτίων',
    agricultural_use: 'Για Αγροτική Χρήση',
    tent: 'Τέντες',
    screens: 'Σίτες',
    bright: 'Φωτεινό',
    painted: 'Βαμμένο',
    double_glazing: 'Διπλά Τζάμια',
    doubleGlazing: 'Διπλά Τζάμια',
    structural_wiring: 'Δομημένη Καλωδίωση',
    zoning: 'Ζώνη',
    road_type: 'Πρόσβαση από',
    cable_ready: 'Καλωδιακή τηλεόραση',
    satellite_receiver: 'Δορυφορική κεραία',
    short_term_letting: 'Βραχυχρόνια Μίσθωση',
    exchange_scheme: 'Αντιπαροχή',
    exchange_scheme_percentage: 'Συντελεστής αντιπαροχής',
    electricity_type: 'Ρεύμα',
    unloading_ramp: 'Ράμπα Εκφόρτωσης',
    suitable_for_professional: 'Κατάλληλο για επαγ.',
    accessible_for_disabled: 'Πρόσβαση για ΑμεΑ',
    luxury: 'Πολυτελής Κατασκευή',
    playroom: 'Playroom',
    boiler: 'Boiler',
    attic: 'Σοφίτα',
    sea_view: 'Θέα Παραλία/Θάλασσα',
    seaView: 'Θέα Παραλία/Θάλασσα',
    mountain_view: 'Θέα Βουνό',
    mountainView: 'Θέα Βουνό',
    park_view: 'Θέα Πάρκο',
    corner: 'Γωνιακό',
    open_house: 'Open House',
    from: 'Από',
    to: 'Εώς',
    none: 'Αδιάφορο',
    facade_length: 'Μήκος πρόσοψης',
    facadeLength: 'Μήκος πρόσοψης',
    balconies_area: 'Εμβαδόν μπαλκονιών',
    editProperty: 'Τροποποίηση Ακινήτου',
    formErrors: 'Η φόρμα του ακινήτου περιέχει σφάλματα!',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    successSave: 'Επιτυχής αποθήκευση ακινήτου',
    category: 'Κατηγορία Ακινήτου',
    availableFrom: 'Διαθέσιμο Από',
    availableFor: 'Διαθέσιμο προς',
    auctionDate: 'Ημερομηνία διενέργειας πλειστηριασμού',
    assignationType: 'Είδος Ανάθεσης',
    state: 'Κατάσταση Ακινήτου',
    assignationState: 'Κατάσταση Ανάθεσης',
    assignationStart: 'Έναρξη Ανάθεσης',
    assignationFinish: 'Λήξη Ανάθεσης',
    offline: 'Offline',
    attributes: 'Χαρακτηριστικά',
    location: 'Τοποθεσία',
    basicInformation: 'Βασικά Στοιχεία',
    media: 'Photo-Video-File',
    auction: 'Πλειστηριασμός',
    actions: 'Ενέργειες-Υποδείξεις',
    basicAttributes: 'Βασικά Χαρακτηριστικά',
    moreAttributes: 'Επιπλέον Στοιχεία',
    locationInformation: 'Πληροφορίες Τοποθεσίας',
    comments: 'Περιγραφή',
    property_management: 'Portals',
    show_on_spitogatos: 'Εμφάνιση ακινήτου Spitogatos',
    show_on_xe: 'Εμφάνιση ακινήτου XE',
    show_on_plot: 'Εμφάνιση ακινήτου Plot',
    rooms: 'Δωμάτια',
    acreage: 'Εμβαδόν (τ.μ.)',
    lot_size: 'Επιφάνεια oικοπέδου',
    user: 'Ιδιοκτήτης',
    owner: 'Ιδιοκτήτης',
    newClient: 'Νέα Επαφή',
    area: 'Περιοχή',
    constructionYear: 'Έτος Κατασκευής',
    renovationYear: 'Έτος Ανακαίνισης',
    addressOnPortals: 'Διεύθυνση για spitogatos (Προαιρετικά διότι δημοσιεύεται)',
    addressOnPlot: 'Διεύθυνση για Plot (Υποχρεωτικό πεδίο για υποβολή στο Plot)',
    assignationStates: 'Κατάσταση Ανάθεσης',
    imageExists: 'Η φωτογραφία με το συγκεκριμένο όνομα υπάρχει ήδη!',
    invalidImageType: 'Μη έγκυρος τύπος φωτογραφίας!',
    fileExists: 'Το αρχείο με το συγκεκριμένο όνομα υπάρχει ήδη!',
    invalidFileType: 'Μη έγκυρος τύπος αρχείου!',
    exceedImagesLimit: 'Επιτρεπεται μεχρι 25 φωτογραφίες!',
    structure_factor: 'Συντελεστής Δόμησης',
    cover_rate: 'Συντελεστής κάλυψης',
    slope: 'Κλίση',
    images: 'Φωτογραφίες',
    images360: 'Φωτογραφίες 360',
    files: 'Αρχεία',
    floor: 'Όροφος',
    levels: 'Επίπεδα',
    square_meters: 'τ.μ.',
    yes: 'Ναι',
    no: 'Όχι',
    title: 'Ακίνητο',
    code: 'Κωδικός Αγγελίας',
    propertyCode: 'Κωδικός Ακινήτου',
    fullCode: 'Πλήρης Κωδικός Αγγελίας',
    bathrooms: 'Μπάνια',
    propertyDesc: 'Περιγραφή',
    propertyCodeShort: 'Κωδ.:',
    subcategory: 'Υποκατηγορία',
    renewed: 'Ανακαινισμένο',
    balconiesArea: 'Επιφάνεια μπαλκονιών',
    shopWindowLength: 'Μήκος Βιτρίνας',
    hotelBedsNumber: 'Αριθμός κλινών (για ξενοδοχείο)',
    orientation: 'Προσανατολισμός',
    electricityType: 'Τύπος Ρεύματος',
    toilets: 'WC',
    seaDistance: 'Απόσταση από Θάλασσα',
    avg_monthly_maintenance_fees: 'Μηνιαία κοινόχρηστα',
    avgMonthlyMaintenanceFees: 'Μηνιαία κοινόχρηστα',
    floorType: 'Τύπος δαπέδων',
    heating: 'Θέρμανση',
    framing: 'Κουφώματα',
    energyClass: 'Ενεργειακή κλάση',
    energyEfficiency: 'Ενεργειακή Απόδοση',
    officeLocation: 'Τοποθεσία μεσιτικού',
    livingRooms: 'Σαλόνια',
    kitchens: 'Κουζίνες',
    price: 'Τιμή',
    status: 'Κατάσταση',
    description: 'Κείμενο Καταχώρησης',
    portalDescription: ' Κείμενο για portals',
    portal: ' Κείμενο για portals',
    contract_copy: 'Αντίγραφο Συμβολαίου',
    building_permit_copy: 'Αντίγραφο Οικοδομικής Άδειας',
    topographic: 'Τοπογραφικό',
    coverage_plan: 'Σχέδιο Κάλυψης',
    floor_plan_copy: 'Αντίγραφο Κάτοψης Ορόφου',
    ground_floor_plan_copy: 'Αντίγραφο Κάτοψης Ισογείου',
    basement_floor_plan_copy: 'Αντίγραφο Κάτοψης Υπογείου',
    land_registry_copy: 'Αντίγραφο Κτηματολογίου',
    pea_copy: 'Αντίγραφο Π.Ε.Α.',
    electricity_provider_copy: 'Αντίγραφο λογ. Δ.Ε.Η.',
    water_provider_copy: 'Αντίγραφο λογ. Εταιρείας Ύδρευσης',
    iasve_copy: 'Αντίγραφο Υ.Α.Σ.Β.Ε.',
    weight_copy: 'Αντίγραφο Βαρών',
    lease_copy: 'Αντίγραφο Μισθωτηρίου',
    banner: 'Πανό',
    keys: 'Κλειδιά',
    joint_ownership: 'Συνιδιοκτησία',
    objective_value: 'Αντικειμενική Αξία',
    record_date: 'Ημερομηνία Καταγραφής',
    mixed_area: 'Εμβαδόν Μικτό',
    net_area: 'Εμβαδόν Καθαρό',
    semi_outdoor_area: 'Εμβαδόν Ημιυπαιθρίων',
    percentage_on_the_plot: 'Ποσοστό επί του Οικοπέδου',
    total_building_apartments: 'Σύνολο Διαμ/των Οι/μής',
    total_building_floors: 'Σύνολο Ορόφων Οικοδομής',
    area_per_floor: 'Εμβαδόν ανά Επίπεδο (1)',
    network_services: 'Παροχές Δικτύων',
    offer_date: 'Ημερομηνία Προσφοράς',
    prepayment_date: 'Ημερομηνία Προκαταβολής',
    act_date: 'Ημερομηνία Πράξης',
    remuneration_rate: 'Ποσοστό Αμοιβής',
    remuneration_amount: 'Ποσό Αμοιβής',
    apy_number: 'Αριθμός Α.Π.Υ.',
    cooperation_with: 'Σε Συνεργασία με',
    kaek: 'ΚΑΕΚ',
    commentsOnProperty: 'Προσωπικές σημειώσεις',
    propertyLocation: 'Τοποθεσία ακινήτου',
    intramail: 'Intramail',
    intrahome: 'Intrahome',
    previous_step: 'Προηγούμενο',
    next_step: 'Επόμενο',
    completion: 'Ολοκλήρωση',
    propertyType: 'Είδος ακινήτου',
    propertyAttributes: 'Χαρακτηριστικά ακινήτου',
    site: 'Website',
    glass_type: 'Είδος υαλοπίνακα',
    price_includes_power: 'Η τιμή περιλαμβάνει τα έξοδα του ρεύματος',
    price_includes_water: 'Η τιμή περιλαμβάνει τα έξοδα του νερού',
    price_includes_internet: 'Η τιμή περιλαμβάνει τα έξοδα του Internet',
    price_includes_shared_exp: 'Η τιμή περιλαμβάνει τα κοινόχρηστα',
    number_of_floors: 'Αριθμός ορόφων στο κτίριο',
    energy_class_protocol_number: 'Αριθμός Πρωτοκόλλου Πιστοποιητικού Ασφαλείας',
    energy_class_security_number: 'Αριθμός Ασφαλείας Πιστοποιητικού Ασφαλείας',
    auction_id: 'Auction ID',
    auction_url: 'Auction URL',
    garden_type: 'Τύπος κήπου',
    storage_area: 'Εμβαδόν αποθήκης',
    manned_reception: 'Υποδοχή με θυρωρό',
    electric_car_charging_facilities: 'Φόρτιση ηλεκτρικών αυτοκινήτων',
    built_in_bbq: 'Εντοιχισμένο μπάρμπεκιου',
    medical_office: 'Κατάλληλο για ιατρικό γραφείο',
    leased: 'Μισθωμένο ακίνητο',
    leased_until: 'Ημερομηνία λήξης τρέχοντος μισθωτηρίου',
    cave_building: 'Υπόσκαφο',
    fire_detectors: 'Πυρανιχνευτές',
    fire_extinguishing_system: 'Σύστημα πυρόσβεσης',
    number_of_entrances: 'Αριθμός εισόδων',
    buildable: 'Οικοδομήσιμο',
    buildable_area: 'Οικοδομήσιμη έκταση',
    within_zoning_plan: 'Εντός οικισμού',
    open_parking_spots: 'Ανοιχτές θέσεις στάθμευσης',
    closed_parking_spots: 'Κλειστές θέσεις στάθμευσης',
    negotiable_price: 'Συζητήσιμη τιμή',
    available_for_daily_rent: 'Διαθέσιμο για ημερήσια ενοικίαση',
    daily_rent: 'Κόστος ημερήσιας ενοικίασης',
    translateDescription: 'Μετάφραση Περιγραφής',
    translatePortal: 'Μετάφραση Κειμένου για portals',
    redirectToGoogleTranslateDescription: 'Ανακατεύθυνση Google Translate Πεδίο Περιγραφή',
    redirectToGoogleTranslatePortal: 'Ανακατεύθυνση Google Translate Πεδίο Portal',
    translateAlertTitle: 'Οδηγίες Χρήσης:',
    translateAlertDescription: 'Η πλατφόρμα μας παρέχει δύο μεθόδους μετάφρασης. Το κουμπί \'Άμεση Μετάφραση\' προσφέρει μια πειραματική υπηρεσία μετάφρασης, η οποία ενδέχεται περιστασιακά να μη λειτουργεί. Σε τέτοιες περιπτώσεις, παρακαλούμε χρησιμοποιήστε το κουμπί \'Μεταφορά στην σελίδα του Google Translate\' για να αποκτήσετε πρόσβαση στην υπηρεσία μετάφρασης της Google.'
  },
  media_types: {
    living_room: 'Σαλόνι',
    kitchen: 'Κουζίνα',
    room: 'Δωμάτιο',
    bathroom: 'Μπάνιο',
    wc: 'WC',
    hall: 'Χωλ',
    balcony: 'Μπαλκόνι',
    view: 'Θέα',
    warehouse: 'Αποθήκη',
    property_facade: 'Πρόσοψη',
    interior_view: 'Άποψη εσωτερικού χώρου',
    outdoor_view: 'Άποψη εξωτερικού χώρου',
    plot_front: 'Πρόσοψη',
    plot_area: 'Περιοχή',
    top_view: 'Κάτοψη',
    opsi: 'Όψη',
    section: 'Τομή',
    topographic: 'Τοπογραφικό'
  },
  groupList: {
    title: 'Ομάδες',
    deleteBulk: 'Οι επιλεγμένες ομάδες πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    delete: 'Η επιλεγμένη ομάδα πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Η ομάδα διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Οι επιλεγμένες ομάδες διαγράφηκαν επιτυχώς!',
    selectGroups: 'Επιλέξτε τις ομάδες που θέλετε να διαγράψετε',
    selectGroup: 'Επιλέξτε ομάδα',
    successSaveGroup: 'Επιτυχής αποθήκευση ομάδας',
    download: 'Εξαγωγή Λίστας',
    create: 'Δημιουργία Ομάδας',
    deleteBulkGroups: 'Μαζική Διαγραφή Ομάδων',
    saveSelectedToGroup: 'Αποθήκευση επιλεγμένων επαφών στην ομάδα',
    deleteSelectedFromGroup: 'Διαγραφή επιλεγμένων επαφών από την ομάδα'
  },
  privilegeList: {
    title: 'Δικαιώματα',
    deleteBulk: 'Τα επιλεγμένα δικαιώματα πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    delete: 'Το επιλεγμένα δικαίωμα πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    deleteSuccess: 'Το επιλεγμένα δικαίωμα διαγράφηκε επιτυχώς!',
    deleteBulkSuccess: 'Τα επιλεγμένα δικαιώματα διαγράφηκαν επιτυχώς!',
    selectGroups: 'Επιλέξτε τα δικαιώματα που θέλετε να διαγράψετε',
    successSave: 'Επιτυχής αποθήκευση δικαιώματος'
  },
  ticket_severity: {
    critical: 'Ένα κρίσιμο πρόβλημα με πολύ μεγάλο αντίκτυπο',
    major: 'Ένα σημαντικό πρόβλημα με σημαντικές επιπτώσεις',
    minor: 'Μικρό πρόβλημα με λίγες επιπτώσεις'
  },
  ticket_status: {
    open: 'Ανοιχτό',
    closed: 'Κλειστό'
  },
  ticket_type: {
    bug: 'Πρόβλημα υπάρχουσας λειτουργικότητας',
    feature: 'Προσθήκη νέας λειτουργικότητας'
  },
  bridge_statuses: {
    error: 'Σφάλμα',
    warning: 'Προειδοποίηση',
    info: 'Ενημέρωση',
    success: 'Επιτυχία'
  },
  dividers: {
    heating_and_consumption: 'Θέρμανση & κατανάλωση',
    basic_elements: 'Βασικά στοιχεία',
    property_areas: 'Χώροι Ακινήτου',
    assignation_info: 'Πληροφορίες Ανάθεσης',
    property_position: 'Εξωτερ. χώροι & θέση ακινήτου',
    technical_features: 'Τεχνικά γνωρίσματα & εσωτερικό',
    construction: 'Κατασκευή',
    ticketMessageHistory: 'Ιστορικό ανταλλαγής μηνυμάτων',
    history: {
      price: 'Ιστορικό Αλλαγής Τιμών',
      status: 'Ιστορικό αλλαγών κατάστασης'
    },
    suitable_for: 'Κατάλληλο για'
  },
  form: {
    hideOnFront: 'Απενεργοποίηση δημόσιας εμφάνισης',
    themeTemplateEnabled: 'Διαχ. Προσωπ. Ιστοσελίδας Ενεργή',
    content: 'Περιεχόμενο',
    datePublished: 'Ημερομηνία Δημοσίευσης',
    externalProperty: 'Εκτός γραφείου',
    draft: 'Πρόχειρο',
    completed: 'Ολοκληρώθηκε',
    published: 'Δημοσιεύτηκε',
    code: 'Κωδικός',
    status: 'Κατάσταση',
    full_code: 'Πλήρης Κωδικός',
    bio: 'Βιογραφικό κείμενο',
    name: 'Όνομα',
    username: 'Όνομα Χρήστη',
    service: 'Υπηρεσία',
    gemh: 'ΓΕΜΗ',
    date: 'Ημ/νία',
    recipients: 'Παραλήπτες',
    summary: 'Σύνοψη',
    tickerTitle: 'Τίτλος αιτήματος',
    recipient: 'Παραλήπτης',
    severity: 'Σημαντικότητα',
    sender: 'Αποστολέας',
    instructions: 'Οδηγίες',
    mas: 'Μ.Α.Σ',
    smsApiToken: 'SMS Api Token',
    smsApiUsername: 'SMS Username',
    smsApiPassword: 'SMS Api Password',
    title: 'Τίτλος',
    subject: 'Θέμα',
    firstName: 'Όνομα',
    pleaseSelect: 'Παρακαλώ Επιλέξτε',
    lastName: 'Επίθετο',
    identityCard: 'Αριθμός ΔΤ',
    foundFrom: 'Μας βρήκε από',
    profession: 'Ειδικότητα',
    specificPhone: 'Συγκεκριμένο Τηλέφωνο',
    phone: 'Τηλέφωνο',
    mainPhone: 'Τηλέφωνο (σταθερό)',
    secondaryPhone: 'Τηλέφωνο (κινητό)',
    address: 'Οδός',
    addressNumber: 'Αριθμός',
    postCode: 'Τ.Κ.',
    description: 'Περιγραφή',
    latitude: 'Γεωγραφικό Πλάτος',
    longitude: 'Γεωγραφικό Μήκος',
    age: 'Ηλικία',
    state: 'Κατάσταση',
    type: 'Τύπος',
    mainEmail: 'Email',
    secondaryEmail: 'Συμπληρωματικό Email',
    fax: 'Φαξ',
    taxIdentificationNumber: 'ΑΦΜ',
    groups: 'Ομάδες',
    group: 'Ομάδα',
    office: 'Γραφείο',
    to: 'Προς',
    user: 'Χρήστης',
    collaborator: 'Συνεργάτης',
    area: 'Περιοχή',
    areas: 'Επιλογή πολλαπλών περιοχών',
    notes: 'Σημειώσεις',
    city: 'Πόλη',
    role: 'Ιδιότητα',
    roles: 'Ρόλοι',
    active: 'Ενεργός',
    property: 'Ακίνητο',
    client: 'Επαφή',
    clients: 'Επαφές',
    googleCalendar: 'Ημερολόγιο Google',
    completionDate: 'Ημ/νία Υπόδειξης',
    cooperationDate: 'Ημ/νία Συνεργασίας',
    rejectionReason: 'Λόγος Απόρριψης',
    required: '*',
    password: 'Κωδικός Πρόσβασης',
    checkPass: 'Επιβεβαίωση κωδικού',
    isRequired: 'Το πεδίο είναι υποχρεωτικό',
    atLeastOnePhone: 'Συμπληρώστε τουλάχιστον ένα τηλέφωνο (είτε σταθερό είτε κινητό)',
    isVat: 'Το ΑΦΜ πρέπει να είναι ακριβώς 9 ψηφία',
    userOrOfficeRequired: 'Παρακαλώ συμπληρώστε είτε χρήστη είτε γραφείο',
    linkedin: 'LinkedIn',
    facebook: 'Facebook',
    youtube: 'Youtube',
    properties: 'Ακίνητα',
    media: 'Πολυμέσα',
    logo: 'Logo',
    emailLanguage: 'Γλώσσα Αποστολής',
    site: 'Ιστοσελίδα',
    formErrors: 'Η φόρμα περιέχει σφάλματα!',
    text: 'Κείμενο',
    ticketMessageText: 'Σχόλιο σχετικό με το αίτημα τεχνολογίας',
    image: 'Εικόνα',
    isEmail: 'Παρακαλώ συμπληρώστε ένα σωστό Email'
  },
  social_media: {
    linkedin: 'LinkedIn',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube'
  },
  api: {
    serverError: 'Προέκυψε κάποιο σφάλμα.',
    entityNotFound: 'Η καταχώρηση',
    codeAlreadyExists: 'Ο συγκεκριμένος κωδικός ακινήτου υπάρχει ήδη',
    emailAlreadyExists: 'Το συγκεκριμένο email υπάρχει ήδη'
  },
  navbar: {
    logOut: 'Salir',
    dashboard: 'Panel de control',
    github: 'Github',
    theme: 'Tema',
    size: 'Tamaño global',
    profile: 'Profile'
  },
  slope: {
    plane: 'Επίπεδο',
    inclining: 'Επικλινές',
    amphitheatric: 'Αμφιθεατρικό'
  },
  locale: {
    title: 'Γλώσσα',
    form: {
      el: 'Πολυγλωσσικά πεδία στα Ελληνικά',
      en: 'Πολυγλωσσικά πεδία στα Αγγλικά',
      it: 'Πολυγλωσσικά πεδία στα Ιταλικά',
      bg: 'Πολυγλωσσικά πεδία στα Βουλγάρικα',
      de: 'Πολυγλωσσικά πεδία στα Γερμανικά',
      es: 'Πολυγλωσσικά πεδία στα Ισπανικά',
      fr: 'Πολυγλωσσικά πεδία στα Γαλλικά',
      he: 'Πολυγλωσσικά πεδία στα Εβραϊκά',
      al: 'Πολυγλωσσικά πεδία στα Αλβανικά',
      ru: 'Πολυγλωσσικά πεδία στα Ρώσικα',
      se: 'Πολυγλωσσικά πεδία στα Σουηδικά',
      tr: 'Πολυγλωσσικά πεδία στα Τούρκικα',
      ar: 'Πολυγλωσσικά πεδία στα Αραβικά',
      cs: 'Πολυγλωσσικά πεδία στα Τσέχικα',
      nl: 'Πολυγλωσσικά πεδία στα Ολλανδικά',
      pl: 'Πολυγλωσσικά πεδία στα Πολωνικά',
      ro: 'Πολυγλωσσικά πεδία στα Ρουμανικά',
      sr: 'Πολυγλωσσικά πεδία στα Σερβικά',
      zh: 'Πολυγλωσσικά πεδία στα Κινέζικα'
    },
    el: 'Ελληνικά',
    en: 'Αγγλικά',
    it: 'Ιταλικά',
    bg: 'Βουλγαρικά',
    de: 'Γερμανικά',
    es: 'Ισπανικά',
    fr: 'Γαλλικά',
    he: 'Εβραϊκά',
    al: 'Αλβανικά',
    ru: 'Ρωσικά',
    se: 'Σουηδικά',
    tr: 'Τουρκικά',
    ar: 'Αραβικά',
    cs: 'Τσέχικα',
    nl: 'Ολλανδικά',
    pl: 'Πολωνικά',
    ro: 'Ρουμανικά',
    sr: 'Σερβικά',
    zh: 'Κινέζικα'
  },
  login: {
    title: 'Σύνδεση Βee Intrahome',
    logIn: 'Σύνδεση',
    recoverPassword: 'Ανάκτηση Κωδικού',
    username: 'Όνομα Χρήστη',
    password: 'Κωδικός',
    forgotPassword: 'Ξέχασα τον κωδικό μου!',
    returnToLogin: 'Επιστροφή στην σελίδα σύνδεσης!',
    emailSend: 'Έχει σταλεί mail με οδηγίες για ανάκτηση κωδικού!',
    authFailure: 'Αποτυχία Σύνδεσης. Παρακαλώ προσπαθήστε ξανά!'
  },
  documentation: {
    documentation: 'Documentación',
    github: 'Repositorio Github'
  },
  permission: {
    createRole: 'Nuevo rol',
    editPermission: 'Permiso de edición',
    roles: 'Tus permisos',
    switchRoles: 'Cambiar permisos',
    tips: 'In some cases it is not suitable to use v-permission, such as element Tab component or el-table-column and other asynchronous rendering dom cases which can only be achieved by manually setting the v-if.',
    delete: 'Borrar',
    confirm: 'Confirmar',
    cancel: 'Cancelar'
  },
  actions: {
    apiDeleteBulk: 'Οι επιλεγμένες καταχωρήσεις πρόκειται να διαγραφούν οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    apiDeleteBulkSuccess: 'Οι επιλεγμένες καταχωρήσεις διαγράφηκαν επιτυχώς!',
    apiDelete: 'Η επιλεγμενη καταχώρηση πρόκειται να διαγραφεί οριστικά. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    apiDeleteSuccess: 'Η επιλεγμενη καταχώρηση διαγράφηκε επιτυχώς!',
    apiSend: 'Το επιλεγμένο email πρόκειται να σταλεί. Θέλετε να προχωρήσετε στην συγκεκριμένη ενέργεια;',
    apiSendSuccess: 'Το επιλεγμένο email στάλθηκε επιτυχώς!',
    apiSuccessSave: 'Επιτυχής αποθήκευση!',
    apiSuccessDelete: 'Επιτυχής διαγραφή!',
    apiSuccessSendEmail: 'Επιτυχής Αποστολή email!',
    apiSuccessSendSms: 'Επιτυχής Αποστολή sms!',
    selectItems: 'Επιλέξτε τις καταχωρήσεις που θέλετε να διαγράψετε!',
    deleteItemBulk: 'Μαζική Διαγραφή Επιλεγμένων',
    sendWithSms: 'Αποστολή με SMS',
    sentForApproval: 'Αποστολή προς Έγκριση',
    draw: 'Σχεδιασμός',
    manageGroups: 'Διαχείριση Ομάδων',
    cancel_draw: 'Ακύρωση Σχεδιασμού',
    saveDescriptions: 'Μαζική αποθήκευση Περιγραφών',
    submit_draw: 'Επικύρωση Σχεδιασμού',
    sendToPortals: 'Αποστολή σε συγκεκριμένο portal',
    sendEmail: 'Αποστολή email',
    create: 'Δημιουργία',
    filters: 'Φίλτρα',
    edit: 'Τροποποίηση',
    hide: 'Απόκρυψη',
    approval: 'Έγκριση',
    accept: 'Αποδοχή',
    reject: 'Απόρριψη',
    delete: 'Διαγραφή',
    cancel: 'Ακύρωση',
    save: 'Αποθήκευση',
    submit: 'Εφαρμογή',
    clear: 'Καθαρισμός',
    close: 'Κλείσιμο',
    preview: 'Προεπισκόπηση',
    back: 'Πίσω',
    change: 'Αλλαγή',
    completion: 'Ολοκλήρωση',
    add: 'Προσθήκη',
    addClone: 'Προσθήκη Αντίγραφου',
    results: 'Αποτελέσματα',
    warning: 'Προσοχή',
    loadMore: 'Φόρτωση Περισσότερων',
    transfer: 'Μεταφορά',
    download: 'Εξαγωγή Λίστας',
    downloadWithClients: 'Εξαγωγή με Επαφές',
    send: 'Αποστολή',
    confirm: 'Επιβεβαίωση',
    moreActions: 'Επιπλέον Ενέργειες',
    addComment: 'Προσθήκη Σχολίου',
    rejectionDialogTitle: 'Λόγος Απόρριψης'
  },
  filters: {
    title: 'Φίλτρα',
    subcategory: 'Υποκατηγορία',
    views: 'Προβολές',
    severity: 'Σημαντικότητα',
    property_state: 'Κατάσταση Ακινήτου',
    results: 'Αποτελ/τα',
    show: 'Αναζήτηση',
    foundFrom: 'Μας βρήκε από',
    profession: 'Ειδικότητα',
    groups: 'Ομάδες',
    dateCreated: 'Ημ/νία Εισ/γής',
    offices: 'Γραφεία',
    personal: 'Προσωπικά',
    personalProperties: 'Προσωπικά Ακίνητα',
    floor: 'Όροφος',
    officeUsers: 'Χρήστες Γραφείου',
    activeUsers: 'Ενεργοί Χρήστες',
    roles: 'Ιδιότητα',
    personalClients: 'Προσωπικές Επαφές',
    activeProperties: 'Ενεργά ακίνητα',
    personalSearches: 'Προσωπικές Ζητήσεις',
    personalVisits: 'Προσωπικές Υποδείξεις',
    activeSearches: 'Ενεργές Αναζητήσεις',
    availableFor: 'Διαθέσιμο προς',
    available_from: 'Διαθέσιμο από',
    kitchens: 'Κουζίνες',
    living_rooms: 'Σαλόνια',
    type: 'Τύπος',
    users: 'Χρήστες',
    clients: 'Επαφές',
    properties: 'Ακίνητα',
    category: 'Κατηγορία',
    offline: 'Offline',
    active: 'Ενεργές',
    needsApproval: 'Προς έγκριση',
    underModification: 'Υπό επεξεργασία',
    price: 'Τιμή',
    acreage: 'Εμβαδόν',
    areas: 'Περιοχές',
    assignationType: 'Είδος Ανάθεσης',
    assignationState: 'Κατάσταση Ανάθεσης',
    visitDate: 'Ημ/νία Επίσκεψης',
    collaborator: 'Συνεργάτης',
    officeFrom: 'Από Γραφείο',
    officeTo: 'Προς Γραφείο',
    collaboratorFrom: 'Από Συν/τη',
    collaboratorTo: 'Προς Συν/τη',
    personalCollaborations: 'Προσωπικές Συν/σίες',
    status: 'Κατάσταση',
    from: 'Από',
    to: 'Εώς'
  },
  table: {
    title: 'Τίτλος',
    office: 'Γραφείο',
    customer: 'Πελάτης',
    message: 'Μήνυμα',
    user: 'Χρήστης',
    code: 'Κωδικός',
    office_code: 'Κωδικός Γραφείου',
    statistics: 'Στατιστικά',
    importance: 'Σημαν/τα',
    type: 'Τύπος',
    search: 'Αναζήτηση',
    add: 'Προσθήκη',
    export: 'Εξαγωγή',
    id: 'ID',
    date: 'Ημ/νία',
    author: 'Χρήστης',
    status: 'Κατάσταση',
    actions: 'Ενέργειες',
    sender: 'Αποστολέας',
    recipients: 'Παραλήπτες',
    subject: 'Θέμα',
    severity: 'Σημαντικότητα',
    properties: 'Ακίνητα',
    edit: 'Επεξεργασία',
    editOfficePage: 'Διαμ. Σελίδας',
    delete: 'Διαγραφή',
    view: 'Προβολή',
    changeOwner: 'Μεταφορά σε Συν.',
    copyProperty: 'Αντίγραφο Ακινήτου',
    cancel: 'Ακύρωση',
    confirm: 'Επιβεβαίωση',
    services: 'Υπηρεσίες',
    fullName: 'Όν/μο',
    age: 'Ηλικία',
    fax: 'Φαξ',
    active: 'Ενεργός',
    inactive: 'Μη Ενεργός',
    activeSearch: 'Ενεργή',
    roles: 'Ρόλοι',
    role: 'Ιδιότητα',
    email: 'Email',
    secondaryEmail: 'Συμπλ/κό Email',
    phone: 'Σταθερό Τηλ/νο',
    mobilePhone: 'Κινητό Τηλ/νο',
    foundFrom: 'Μας βρήκε από',
    profession: 'Ειδικότητα',
    dateCreated: 'Ημ/νία Εισ/γής',
    callDate: 'Ημ/νία Κλήσης',
    availableFor: 'Διαθέσιμο Προς',
    dateUpdated: 'Τελευταία Ενημ/ση',
    datePublished: 'Ημ/νία Δημο/σης',
    auctionSite: 'Site e-Auction',
    name: 'Όνομα',
    owner: 'Ιδιοκτήτης',
    area: 'Περιοχή',
    collaborator: 'Συν/της',
    price: 'Τιμή',
    acreage: 'Εμβαδόν',
    field: 'Πεδίο',
    floor: 'Όροφος',
    notes: 'Σημειώσεις',
    property: 'Ακίνητο',
    client: 'Επαφή',
    clientPhone: 'Τηλέφωνα Επαφής',
    category: 'Κατηγορία',
    views: 'Προβολές',
    confirmationDate: 'Ημ. έγκρισης',
    completionDate: 'Ημ/νία Υπ/ξης',
    squareMetersFrom: 'Τ.Μ. από',
    squareMetersTo: 'Τ.Μ. εώς',
    euroPriceFrom: '€ από',
    euroPriceTo: '€ εώς',
    constructionYearFrom: 'Έτος από',
    constructionYearTo: 'Έτος εώς',
    groups: 'Ομάδες',
    lastLogin: 'Τε/ταία Σύνδεση',
    percentage: 'Ποσοστό',
    officeFrom: 'Από Γραφείο',
    officeTo: 'Προς Γραφείο',
    collaboratorFrom: 'Από Συν/τη',
    collaboratorTo: 'Προς Συν/τη',
    ika: 'ΙΚΑ',
    site: 'Site',
    intrahome: 'Intrahome',
    intrahomeNewTab: 'Επεξ. νέα καρτέλα',
    currentVersion: 'Τρέχουσα Τιμή',
    previousValue: 'Προηγούμενη Τιμή',
    newValue: 'Τρέχουσα Τιμή',
    previousVersion: 'Προηγούμενη Τιμή'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Show Tags-View',
    showSidebarLogo: 'Show Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  search: {
    ignored_properties: 'Ακίνητα σε απόκρυψη',
    title: 'Αναζήτηση με όνομα',
    county: 'Νομός',
    municipality: 'Δήμος',
    municipalities: 'Δήμοι',
    search_all: 'Αναζήτηση νομού, δήμου',
    areas: 'Περιοχές',
    area: 'Περιοχή',
    confirm: 'ΕΠΙΒΕΒΑΙΩΣΗ',
    all: 'Όλα',
    no_results: 'Δεν βρέθηκαν αποτελέσματα',
    something_went_wrong: 'Κάτι πήγε στραβά'
  },
  search_filters: {
    title: 'Φίλτρα'
  },
  search_options: {
    title: 'Επιλογή τύπου αναζήτησης',
    search_by_text: 'Αναζήτηση με όνομα',
    search_by_text_descr: 'Πληκτρολόγησε το όνομα μιας περιοχής για αναζήτηση',
    search_by_draw_map: 'Σχεδίασε περιοχή στο χάρτη',
    search_by_draw_map_descr: 'Σχεδίασε απευθείας στο χάρτη την περιοχή που θέλεις να αναζητήσεις',
    search_by_map_radius: 'Επίλεξε το κέντρο και την ακτίνα αναζήτησης',
    search_by_map_radius_descr: 'Ψάξε κοντά στη θέση σου ή σε ένα σημείο επιλογής σου'
  },
  home: {
    title: 'Bee real estate',
    start_new_search: 'ΝΕΑ ΑΝΑΖΗΤΗΣΗ',
    search: 'ΑΝΑΖΗΤΗΣΗ',
    latest_properties: 'Πρόσφατες αγγελίες'
  },
  draw_map: {
    title: 'Αναζήτηση σε χάρτη',
    draw_info: 'Σχεδίασε απευθείας στο χάρτη την περιοχή που θέλεις να αναζητήσεις',
    confirm: 'ΕΠΙΒΕΒΑΙΩΣΗ',
    draw_area: 'ΣΧΕΔΙΑΣΕ ΤΗΝ ΠΕΡΙΟΧΗ'
  },
  radius_map: {
    title: 'Αναζήτηση σε χάρτη',
    choose_a_point: 'Επίλεξε ένα σημείο στον χάρτη',
    confirm: 'ΕΠΙΒΕΒΑΙΩΣΗ',
    meters: 'μ',
    kilometers: 'χλμ'
  },
  properties_page: {
    no_price: 'Κατόπιν Επικοινωνίας',
    title: 'Αποτελέσματα Αναζήτησης',
    month: 'Μήνα',
    square_meters: 'τ.μ.',
    sort_by_default: 'Αυτόματη Ταξινόμηση',
    sort_by_price_desc: 'Φθίνουσα τιμή',
    sort_by_acreage_desc: 'Φθίνον εμβαδόν',
    sort_by_most_recent: 'Πιο πρόσφατα',
    sort_by_price_asc: 'Αύξουσα τιμή',
    sort_by_price_acreage_asc: 'Αύξον εμβαδόν',
    sort_by_less_recent: 'Λιγότερο Πρόσφατα'
  },
  found_from: {
    none: 'Άγνωστο',
    bee_website: 'bee-realestate.gr',
    xe: 'xe.gr',
    spitogatos: 'spitogatos.gr',
    tospitimou: 'tospitimou.gr',
    spiti24: 'spiti24.gr',
    social_networks: 'social',
    internet: 'Internet',
    aggelioforos: 'Αγγελιοφόρος',
    newspaper_magazine: 'Εφημερίδες-Περιοδικά',
    banner: 'Πανό-Πωλητήρια-Ενοικιαστήρια',
    newsletter: 'Newsletter',
    office_advertising: 'Διαφήμιση γραφείου',
    open_house: 'Open House',
    office_visitor: 'Επισκέπτης γραφείου',
    other_office_recommendation: 'Σύσταση άλλου γραφείου',
    known_recommendation: 'Σύσταση από γνωστό',
    personal_contact: 'Προσωπική επαφή'
  },
  profession: {
    none: 'Άγνωστο',
    self_employed: 'Ελ. Επαγγελματίας',
    civil_servant: 'Δημ. Υπάλληλος',
    businessman: 'Επιχειρηματίας',
    private_employee: 'Ιδ. Υπάλληλος',
    broker: 'Μεσίτης',
    contractor: 'Εργολάβος',
    engineer: 'Μηχανικός',
    notary: 'Συμβολαιογράφος',
    retired: 'Συνταξιούχος',
    layer: 'Δικηγόρος',
    doctor: 'Γιατρός'
  },
  property_type: {
    none: 'Αδιάφορο',
    apartment: 'Διαμέρισμα',
    bedsit: 'Γκαρσονιέρα',
    maisonette: 'Μεζονέτα',
    detached_house: 'Μονοκατοικία',
    villa: 'Βίλλα',
    loft: 'Loft',
    bungalow: 'Bungalow',
    building: 'Κτίριο',
    apartment_complex: 'Συγκρότημα διαμερισμάτων',
    farm: 'Φάρμα / Ράντσο',
    houseboat: 'Πλωτό σπίτι',
    multi_level_apartment: 'Διαμέρισμα πολλών επιπέδων',
    office: 'Γραφείο',
    shop: 'Κατάστημα',
    warehouse: 'Αποθήκη',
    industrial_area: 'Βιομηχανικός χώρος',
    craft_space: 'Βιοτεχνικός χώρος',
    hotel: 'Ξενοδοχείο',
    business_building: 'Κτίριο επαγγελματικών χώρων',
    hall: 'Αίθουσα',
    showroom: 'Εκθεσιακός χώρος',
    holding: 'Οικόπεδο',
    parcel: 'Αγροτεμάχιο',
    parcel_within_zone: 'Αγροτεμάχιο εντός ζώνης',
    parcel_under_integration: 'Αγροτεμάχιο υπό ένταξη',
    island: 'Νησί',
    parking: 'Πάρκινγκ',
    business: 'Επιχείρηση',
    prefabricated: 'Προκατασκευασμένο',
    detachable: 'Λυόμενο',
    air: 'Αέρας',
    catering_industry: 'Εστίαση',
    trade: 'Εμπόριο',
    services: 'Παροχή υπηρεσιών',
    other_business: 'Λοιπές επιχειρήσεις',
    other_real_estate: 'Λοιπά ακίνητα'
  },
  property_category: {
    none: 'Καμία',
    residence: 'Κατοικία',
    business_roof: 'Επαγγελματικά',
    land: 'Γη',
    business: 'Επιχειρήσεις',
    student: 'Φοιτητικά'
  },
  property_rooms: {
    single_area: 'Ενιαίος Χώρος',
    ten_plus: '10+'
  },
  property_levels: {
    eighth_and_above: '8 και άνω'
  },
  property_bathrooms: {
    fifth_and_above: '5 και άνω'
  },
  property_toilets: {
    fifth_and_above: '5 και άνω'
  },
  property_state: {
    title: 'Κατάσταση',
    incomplete: 'Ημιτελές',
    under_construction: 'Υπό Ανέγερση',
    newly_build: 'Νεόδμητο',
    perfect_state: 'Άριστη Κατάσταση',
    good_state: 'Καλή κατάσταση',
    needs_renovation: 'Χρήζει Ανακαίνισης',
    renovated: 'Ανακαινισμένο',
    partly_renovated: 'Μερικώς Ανακαινισμένο'
  },
  rec_status: {
    newly_added: 'Αναμονή',
    approved: 'Αποδεκτή',
    rejected: 'Μη Αποδεκτή',
    canceled: 'Ακυρώθηκε'
  },
  services: {
    spitogatos: 'Spitogatos',
    xe: 'Χρυσή Ευκαιρία',
    plot: 'Plot'
  },
  property_status: {
    in_process: 'Υπό επεξεργασία (δεν έχει σταλεί για έγκριση)',
    sent_for_approval: 'Έχει σταλεί για έγκριση (δεν μπορεί να γίνει καμία επεξεργασία)',
    approved: 'Το ακίνητο έχει εγκριθεί',
    changes_required: 'To ακίνητο δεν έχει εγκριθεί και θα πρέπει να γίνουν αλλαγές',
    sold_or_rented_by_office: 'Έχει πουληθεί/νοικιαστεί από το γραφείο',
    sold_or_rented_by_owner: 'Έχει πουληθεί/νοικιαστεί από τον ιδιοκτήτη',
    withdrawn_by_owner: 'Έχει αποσυρθεί από τον ιδιοκτήτη',
    expired: 'Ληγμένο',
    inactive: 'Μη ενεργό',
    needs_transfer: 'Ανενεργός συνεργάτης'
  },
  available_for: {
    title: 'Διαθέσιμο Προς',
    none: 'Καμία',
    sale: 'Πώληση',
    lease: 'Εκμίσθωση',
    compensation: 'Αντιπαροχή'
  },
  assignation_state: {
    active: 'Ενεργό',
    inactive: 'Μη ενεργό',
    withdrawn_from_owner: 'Αποσύρθηκε από τον ιδιοκτήτη',
    under_negotiation: 'Υπό διαπραγμάτευση',
    under_offer_status: 'Υπό καθεστώς προσφοράς',
    sold: 'Πωλήθηκε',
    offline: 'Offline',
    sold_from_owner: 'Πωλήθηκε από τον ιδιοκτήτη',
    expired: 'Ληγμένο'
  },
  assignation_type: {
    exclusive: 'Αποκλειστική',
    simple: 'Απλή'
  },
  floor: {
    none: 'Αδιάφορο',
    below_basement: 'ΥΠ (-2)',
    basement: 'ΥΠ',
    semibasement: 'ΗΜ-ΥΠ',
    ground_floor: 'ΙΣ',
    mezzanine: 'ΗΜ',
    first: '1ος',
    second: '2ος',
    third: '3ος',
    fourth: '4ος',
    fifth: '5ος',
    sixth: '6ος',
    seventh: '7ος',
    eighth_and_above: '8ος και πάνω'
  },
  energy_class: {
    none: 'Αδιάφορο',
    Α_PLUS: 'Α+',
    A: 'Α',
    B_PLUS: 'Β+',
    B: 'Β',
    G: 'Γ',
    D: 'Δ',
    E: 'Ε',
    Z: 'Ζ',
    H: 'H',
    exemption: 'Εξαιρείται',
    under_issuance: 'Υπό Έκδοση'
  },
  sea_distance: {
    none: 'Αδιάφορο',
    first_sea_apartment: '1ο Θάλασσα',
    below_100_meters: '2ο Θάλασσα ως 100μ',
    between_101_and_500_meters: '101μ. ως 500μ',
    above_500_meters: '501μ. και πάνω'
  },
  framing: {
    none: 'Αδιάφορο',
    wooden: 'Ξύλινα',
    aluminium: 'Αλουμινίου',
    synthetic: 'Συνθετικά'
  },
  heating: {
    none: 'Καμία',
    central_oil: 'Κεντρική πετρέλαιο',
    central_gas: 'Κεντρική αέριο',
    independent_oil: 'Αυτόνομη πετρέλαιο',
    independent_gas: 'Αυτόνομη αέριο',
    individual_gas: 'Ατομικό αέριο',
    heat_accumulators: 'Θερμοσυσσωρευτές',
    air_condition: 'Κλιματισμός',
    heat_pump: 'Αντλία θερμότητας',
    gas: 'Υγραέριο',
    stove: 'Σόμπα',
    pellet: 'Pellet',
    infrared: 'Υπέρυθρες',
    fan_coil: 'Fan coil',
    wood: 'Ξύλα',
    teleheating: 'Τηλεθέρμανση',
    geothermal_energy: 'Γεωθερμική ενέργεια',
    other: 'Άλλο'
  },
  electricity_type: {
    industrial: 'Βιομηχανικό',
    single_phase: 'Μονοφασικό',
    three_phase: 'Τριφασικό'
  },
  road_type: {
    asphalt: 'Άσφαλτος',
    pedestrian: 'Πεζόδρομος',
    paved: 'Πλακόστρωτο',
    dirt_road: 'Χωματόδρομος',
    no_road_access: 'Δεν υπάρχει πρόσβαση',
    sea: 'Θάλασσα',
    other: 'Αλλού'
  },
  zoning: {
    agricultural: 'Αγροτική',
    commercial: 'Εμπορική',
    industrial: 'Βιομηχανική',
    recreational: 'Ανάπλασης',
    residential: 'Οικιστική',
    unincorporated: 'Εκτός Σχεδίου'
  },
  confirmation_date: {
    none: 'Αδιάφορο',
    last_twenty_four_hours: 'Τελευταίες 24 ώρες',
    last_three_days: 'Τελευταίες 3 ημέρες',
    last_seven_days: 'Τελευταίες 7 ημέρες',
    last_month: 'Τελευταίος μήνας',
    last_three_months: 'Τελευταίοι 3 μήνες',
    last_six_months: 'Τελευταίοι 6 μήνες',
    last_year: 'Τελευταίος χρόνος'
  },
  orientation: {
    east: 'Ανατολικός',
    east_west: 'Ανατολικοδυτικός',
    east_meridian: 'Ανατολικομεσημβρινός',
    north: 'Βόρειος',
    north_east: 'Βορειοανατολικός',
    north_west: 'Βορειοδυτικός',
    west: 'Δυτικός',
    west_meridian: 'Δυτικομεσημβρινός',
    meridian: 'Μεσημβρινός',
    south: 'Νότιος',
    south_east: 'Νοτιοανατολικός',
    south_west: 'Νοτιοδυτικός',
    not_available: 'Δεν είναι διαθέσιμο'
  },
  floor_type: {
    none: 'Καμία',
    marble: 'Μάρμαρο',
    wood: 'Ξύλο',
    stone: 'Πέτρα',
    laminate: 'Συνθετικό',
    ceramic_tiles: 'Πλακάκι',
    mosaic_tiles: 'Μωσαϊκό',
    wood_and_marble: 'Μάρμαρο/Ξύλο',
    marble_and_tile: 'Μάρμαρο/Πλακάκι',
    wood_and_stone: 'Πέτρα/Ξύλο',
    stone_and_marble: 'Πέτρα/Μάρμαρο',
    wood_and_tile: 'Πλακάκι/Ξύλο',
    wood_and_mosaic: 'Μωσαϊκό/Ξύλο',
    industrial: 'Βιομηχανικό δάπεδο',
    not_available: 'Δεν είναι διαθέσιμο'
  },
  glass_type: {
    single: 'Μονός',
    double: 'Διπλός',
    triple: 'Τριπλός'
  },
  garden_type: {
    private: 'Ιδιωτικός κήπος',
    shared: 'Κοινόχρηστος κήπος'
  },
  page_builder_links: {
    apartments_for_sale: 'Κατοικίες πώληση',
    apartments_for_lease: 'Κατοικίες ενοικίαση',
    business_roof_for_sale: 'Επαγγ. πώληση',
    business_roof_for_lease: 'Επαγγ. ενοικίαση',
    land_for_sale: 'Γη πώληση',
    land_for_lease: 'Γη ενοικίαση'
  },
  promotional_actions: {
    update_collaborators_by_phone: 'Ενημέρωση 20 Συνεργατών Τηλεφωνικά',
    update_profs_by_phone: 'Ενημέρωση 20 Επαγγελματιών Τηλεφωνικά',
    update_customers_by_letter: 'Ενημέρωση Πελατολογίου με Επιστολή',
    ad_brochures: 'Διαφημιστικά Έντυπα με Χαρακτηριστικά και Φωτογραφίες του Ακινήτου, Μέσα σε Μαγαζιά της Γειτονιάς',
    sale_announcement: 'Πωλητήριο στον Πίνακα Ανακοινώσεων του γραφείου',
    newsletter: 'Newsletter',
    open_house: 'Open House',
    update_neighbors_door_to_door: 'Ενημέρωση των Γειτόνων, με Προσωπική Επίσκεψη, Πόρτα-Πόρτα',
    banner: 'Πανό',
    neighbor_posters: 'Πωλητήρια στη Γειτονιά',
    newspaper_post: 'Έγχρωμη Καταχώρηση/Διαφήμιση σε Εφημερίδα',
    collaborator_notes: 'Σχόλια Συνεργάτη'
  },
  statistics: {
    title: 'Στατιστικά',
    personal_calls: 'Προσωπικές Κλήσεις',
    visits: 'Υποδείξεις',
    inside_office_visits: 'Υποδείξεις (Προσωπικές/Γραφείου)',
    outside_office_visits: 'Υποδείξεις Εκτός Γραφείου',
    recommendations: 'Συστάσεις',
    incoming_recommendations: 'Εισερχόμενες Συστάσεις',
    outgoing_recommendations: 'Εξερχόμενες Συστάσεις',
    collaborations: 'Συνεργασίες',
    outgoing_collaborations: 'Εξερχόμενες Συνεργασίες',
    incoming_collaborations: 'Εισερχόμενες Συνεργασίες',
    assignations: 'Αναθέσεις',
    properties: 'Ακίνητα',
    simple_sale: 'Απλή Πώληση',
    exclusive_sale: 'Αποκλειστική Πώληση',
    simple_lease: 'Απλή Ενοικίαση',
    exclusive_lease: 'Αποκλειστική Ενοικίαση',
    active_searches: 'Ενεργές Ζητήσεις',
    rejection_reason: 'Λόγος Απόρριψης',
    inactive_service: 'Ανενεργή Υπηρεσία για το συγκεκριμένο ακίνητο'
  },
  property_traffic: {
    location: 'Τοποθεσία',
    visits: 'Προβολές',
    last_visit: 'Τελευταία Προβολή'
  }
}
